/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
export var NotificationType;
(function (NotificationType) {
  NotificationType["Success"] = "success";
  NotificationType["Error"] = "error";
  NotificationType["Information"] = "information";
})(NotificationType || (NotificationType = {}));
export var SenderType;
(function (SenderType) {
  SenderType["System"] = "system";
  SenderType["User"] = "user";
})(SenderType || (SenderType = {}));
export var TopicType;
(function (TopicType) {
  TopicType["ImageExport"] = "imageExport";
  TopicType["VideoExport"] = "videoExport";
})(TopicType || (TopicType = {}));